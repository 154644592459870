import { useMediaQuery } from '@material-ui/core';
import { supportsPassiveEvents } from 'detect-passive-events';
import { useEffect, useState } from 'react';

const breakpointWidth = 1023;

export const useIsMobile = () => {
  const mobile = useMediaQuery(`(max-width: ${breakpointWidth}px)`);
  // return mobile || isMobile;
  return mobile;
};

export enum SCROLL_DIRECTION {
  Y,
  X,
}

export const useScroll = (targetNode: any, direction?: SCROLL_DIRECTION) => {
  const [scrollProgress, setScrollProgress] = useState(0);

  useEffect(() => {
    const scrollDirection = direction || SCROLL_DIRECTION.Y;

    const onScroll = (event: any) => {
      const scrollY =
        event.currentTarget.scrollY !== undefined
          ? event.currentTarget.scrollY
          : event.currentTarget.scrollTop;
      const scrollX =
        event.currentTarget.scrollX !== undefined
          ? event.currentTarget.scrollX
          : event.currentTarget.scrollLeft;
      setScrollProgress(SCROLL_DIRECTION.Y === scrollDirection ? scrollY : scrollX);
    };

    if (targetNode) {
      targetNode.addEventListener(
        'scroll',
        onScroll,
        supportsPassiveEvents ? { passive: true, capture: false } : false,
      );
    }
    return () => {
      if (targetNode) {
        targetNode.removeEventListener('scroll', onScroll);
      }
    };
  }, [targetNode, direction]);
  return scrollProgress;
};

export const scrollTo = (el: HTMLElement) => {
  el.scrollIntoView({ behavior: 'smooth', block: 'start' });
  /*
  const headerOffset = 100;
  const elPosition = el.getBoundingClientRect().top;
  const offsetPosition = elPosition - headerOffset;

  window.scrollTo({
    top: offsetPosition,
    behavior: 'smooth',
  });
  */
};

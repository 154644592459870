import { createMuiTheme, MuiThemeProvider } from '@material-ui/core';
import * as React from 'react';
import { render } from 'react-dom';
import smoothscroll from 'smoothscroll-polyfill';
import './index.scss';
import Routing from './Routing';
import './theme/styling';

import { primary } from './theme/colors';

// kick off the polyfill!
smoothscroll.polyfill();

const theme = createMuiTheme({
  palette: {
    type: 'light',
    primary: { 500: primary },
  },
  typography: {},
});

const App: React.FC = () => {
  return (
    <MuiThemeProvider theme={theme}>
      <React.Suspense fallback={null}>
        <Routing />
      </React.Suspense>
    </MuiThemeProvider>
  );
};

render(<App />, document.getElementById('root'));

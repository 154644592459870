import * as React from 'react';
import { Col, Container, Row } from 'reactstrap';
import { Fade } from 'react-reveal';
import ReactTextTransition, { presets } from 'react-text-transition';

import { primary } from 'theme/colors';
import { useEffect, useState } from 'react';

const urls = require('../data/urls.json');

const shapes = [
  { duration: 1500, delay: 100 },
  { duration: 1000, delay: 100 },

  { duration: 500, delay: 200 },
  { duration: 500, delay: 200 },

  { duration: 1000, delay: 200 },
  { duration: 1000, delay: 200 },
  { duration: 1000, delay: 100 },

  { duration: 1000, delay: 100 },
  { duration: 1000, delay: 300 },
];

const ROLL_STRINGS = ['DESIGN', 'PROTOTYPE', 'MARKETING', 'USABILITY'];
const SHOW_PRODUCT_HUNT = false;

const Heading = () => {
  const [textIndex, setTextIndex] = useState(0);
  useEffect(() => {
    const interval = setInterval(() => {
      if (textIndex < ROLL_STRINGS.length - 1) {
        setTextIndex(textIndex + 1);
      } else {
        setTextIndex(0);
      }
    }, 1500);
    return () => {
      clearInterval(interval);
    };
  });
  return (
    <header className="header alter2-header section">
      <div className="shapes-container">
        {/* diagonal shapes */}
        {shapes.map((shape, i) => (
          <Fade key={i} bottom={true} duration={shape.duration} delay={shape.delay}>
            <div className="shape shape-animated shape-diagonal" />
          </Fade>
        ))}

        {/* animated shapes */}
        <div className="animation-shape shape-ring animation--clockwise" />
        <div className="animation-shape shape-circle shape-circle-1 animation--anti-clockwise" />
        <div className="animation-shape shape-circle shape-circle-2 animation--clockwise" />
        <div className="animation-shape shape-diamond animation--anti-clockwise">
          <div className="animation--rotating" />
        </div>

        <div className="static-shape shape-circle shape-circle-1">
          <Fade top={true} right={true}>
            <div />
          </Fade>
        </div>

        <div className="static-shape shape-circle shape-circle-2">
          <Fade top={true} right={true} delay={500}>
            <div />
          </Fade>
        </div>

        {/*  main shape */}
        <img src="/assets/blob.svg" alt="blob" />
      </div>

      <Container className="text-left">
        <Row>
          <Col md="6">
            <div
              style={{
                backgroundColor: 'rgba(255,255,255,0.5)',
                width: 'fit-content',
                borderRadius: '10px',
                margin: 'auto',
                padding: '10px',
              }}
            >
              <h1 className="bold text-primary">
                <ReactTextTransition
                  text={ROLL_STRINGS[textIndex]}
                  springConfig={presets.gentle}
                  style={{ margin: '0px' }}
                  inline={true}
                />
                <br />
                ANALYTICS
              </h1>

              <h2 className="lead justify">
                An all-in-one analytics platform covering every touchpoint. From design prototypes
                and surveys to live marketing campaigns. Each interaction is valuable. Learn from
                it!
              </h2>
              <span
                style={{
                  textTransform: 'uppercase',
                  fontSize: '0.8em',
                  fontWeight: 'bold',
                  color: primary,
                }}
              >
                Gain insights in minutes. No credit card.
              </span>
              <nav className="nav my-5">
                <a
                  href={urls.signup}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="btn btn-primary mr-2 mr-md-5"
                >
                  Get Started
                </a>
                <a
                  href={'/#plugins'}
                  style={{ fontSize: '75%', whiteSpace: 'nowrap', lineHeight: '36px' }}
                >
                  IMPORT DESIGN
                </a>
              </nav>
            </div>
          </Col>

          <Col md="6" style={{ display: 'flex' }}>
            <div style={{ margin: 'auto' }}>
              <div className="screen drop-shadow">
                <img style={{ width: '100%' }} src={'assets/imgs/devices.svg'} alt="heatmaps" />
              </div>
            </div>
          </Col>
        </Row>
        {SHOW_PRODUCT_HUNT && (
          <Row>
            <Col
              md="12"
              style={{ display: 'flex', justifyContent: 'center', alignContent: 'center' }}
            >
              <div style={{ margin: '4rem', marginBottom: 0, textAlign: 'center' }}>
                <div
                  style={{
                    margin: '1rem',
                    padding: '1rem',
                    paddingLeft: '2rem',
                    paddingRight: '2rem',
                    backgroundColor: 'rgba(255,255,255, 0.5)',
                    color: 'rgb(78,88,121)',
                    border: 'solid 1px rgba(78,88,121, 0.3)',
                    borderRadius: '10px',
                  }}
                >
                  <p style={{ userSelect: 'none' }}>
                    🚀 We've just introduced a fresh feature set on ProductHunt. <br />
                    Each 👍 is greatly appreciated. Thank you for your support!
                  </p>
                  <p>
                    <a
                      href="https://www.producthunt.com/posts/mupixa-for-augmented-reality-ar?utm_source=badge-featured&utm_medium=badge&utm_souce=badge-mupixa&#0045;for&#0045;augmented&#0045;reality&#0045;ar"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <img
                        src="https://api.producthunt.com/widgets/embed-image/v1/featured.svg?post_id=456799&theme=neutral"
                        alt="Mupixa&#0032;for&#0032;Augmented&#0032;Reality&#0032;&#0040;AR&#0041; - An&#0032;all&#0045;in&#0045;one&#0032;analytics&#0032;platform&#0032;covering&#0032;every&#0032;touchpoint&#0046; | Product Hunt"
                        style={{ width: '250px', height: '54px' }}
                        width="250"
                        height="54"
                      />
                    </a>
                  </p>
                </div>
              </div>
            </Col>
          </Row>
        )}
      </Container>
    </header>
  );
};

export default Heading;

import * as React from 'react';
import { makeStyles } from '@material-ui/core';
import { primary, primaryMute } from '../theme/colors';
import { useScroll } from '../utils/media';

interface ScrollIndicatorProps {}

const ScrollIndicator: React.FC<ScrollIndicatorProps> = (props) => {
  const classes = useStyles(props);
  const scrollProgress = useScroll(window);

  return (
    <div
      className={classes.scrollIndicator}
      style={{
        width: `${(scrollProgress / (document.body.scrollHeight - window.screen.height)) * 100}%`,
      }}
    />
  );
};

const useStyles = makeStyles(() => ({
  scrollIndicator: {
    zIndex: 1500,
    position: 'fixed',
    top: '0px',
    left: '0px',
    height: '7px',
    maxWidth: '100%',
    backgroundImage: `linear-gradient(to bottom right, ${primary}, ${primaryMute})`,
  },
}));

export default ScrollIndicator;

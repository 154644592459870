import React from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  makeStyles,
  Button,
  TextField,
  CircularProgress,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import * as EmailValidator from 'email-validator';
import axios from 'axios';

import { primary, textLight, white } from '../theme/colors';

const urls = require('../data/urls.json');

const DEMO_API_URL = 'https://api.mupixa.com/landingpage/demo';

enum Status {
  NOOP,
  LOADING,
  OK,
  ERR,
}

interface Props {
  open: boolean;
  close: () => void;
}

const EmailCollector: React.FC<Props> = (props) => {
  const classes = useStyles(props);
  const [email, setEmail] = React.useState('');
  const [status, setStatus] = React.useState(Status.NOOP);
  const shouldCheck = email.length >= 5; // a@b.c  min 5 char
  const validEmail = shouldCheck ? EmailValidator.validate(email) : true;

  const sendEmail = () => {
    if (shouldCheck && validEmail && status === Status.NOOP) {
      const config = {
        method: 'POST',
        url: DEMO_API_URL,
        params: {},
        data: {
          email,
        },
        headers: {
          'Content-Type': 'application/json',
        },
      } as const;
      setStatus(Status.LOADING);
      axios
        .request(config)
        .then(() => {
          setStatus(Status.OK);
        })
        .catch(() => {
          setStatus(Status.ERR);
        });
    }
  };

  return (
    <Dialog
      open={props.open}
      onClose={props.close}
      aria-labelledby="form-dialog-security"
      classes={{
        paper: classes.paper,
      }}
    >
      <DialogTitle className={classes.dialogTitle}>
        <div>
          <span>EXPLORE ANALYTICS</span>
          <Button className={classes.closeBtn} onClick={props.close}>
            <CloseIcon fontSize="small" />
          </Button>
        </div>
      </DialogTitle>
      <DialogContent>
        <div className={classes.background} />
        <div style={{ marginTop: '8px' }}>
          {status === Status.NOOP && (
            <React.Fragment>
              <p>
                Please provide a valid email address and we will send you the demo link to your
                email.
              </p>
              <div style={{ fontSize: '10px' }}>
                <span>it takes approx. 42 seconds</span>
              </div>
              <div>
                <TextField
                  value={email}
                  autoFocus
                  error={shouldCheck && !validEmail}
                  label={validEmail ? 'Email' : 'Invalid Email'}
                  margin="dense"
                  type="email"
                  fullWidth
                  placeholder="your@email.com"
                  onChange={(event: any) => {
                    setEmail(event.target.value);
                  }}
                  onKeyDown={(event: React.KeyboardEvent) => {
                    if (event.key === 'Enter') {
                      event.preventDefault();
                      sendEmail();
                    }
                  }}
                />
              </div>
            </React.Fragment>
          )}
          {status === Status.OK && (
            <React.Fragment>
              <p>Check your emails.</p>
              <div style={{ fontSize: '10px' }}>
                <span>If you don't find our email. Check the spam folder as well.</span>
              </div>
            </React.Fragment>
          )}
          {status === Status.LOADING && <CircularProgress color="primary" />}
          {status === Status.ERR && (
            <React.Fragment>
              <p>Something went wront. Here is the link to the demo analytics:</p>
              <p>
                <a href={urls.demoAnalytics} target="_blank" rel="noopener noreferrer">
                  link
                </a>
              </p>
            </React.Fragment>
          )}
        </div>
      </DialogContent>
      <DialogActions>
        {status === Status.NOOP && (
          <React.Fragment>
            <Button variant="outlined" color="primary" onClick={props.close}>
              CANCEL
            </Button>
            <Button
              disabled={!shouldCheck || !validEmail}
              variant="contained"
              color="primary"
              onClick={() => {
                sendEmail();
              }}
            >
              SEND
            </Button>
          </React.Fragment>
        )}
        {(status === Status.ERR || status === Status.OK) && (
          <Button
            variant="contained"
            color="primary"
            onClick={() => {
              props.close();
            }}
          >
            CLOSE
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
};

const useStyles = makeStyles(() => ({
  paper: {
    width: '70%',
    textAlign: 'center',
  },
  dialogTitle: {
    backgroundColor: primary,
    color: white,
    padding: '0px',
  },
  dialogContent: {
    color: textLight,
    fontSize: '0.85em',
    fontWeight: 'normal',
    margin: '10px',
  },
  closeBtn: {
    position: 'absolute',
    color: white,
    borderRadius: '0px',
    top: '0px',
    right: '0px',
  },
  background: {
    width: 'calc(100% + 48px)',
    height: '220px',
    backgroundImage: 'url(/assets/imgs/analytics-design-light.png)',
    backgroundRepeat: 'no-repeat',
    backgroundSize: '100%',
    backgroundPositionY: 'center',
    marginLeft: '-24px',
    marginRight: '-24px',
    marginTop: '-8px',
  },
}));

export default EmailCollector;

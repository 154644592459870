import React, { ChangeEvent, FC, ReactNode, useState } from 'react';
import { makeStyles, Theme } from '@material-ui/core/styles';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import { Paper } from '@material-ui/core';

interface TabPanelProps {
  children?: ReactNode;
  index: any;
  value: any;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-auto-tabpanel-${index}`}
      aria-labelledby={`scrollable-auto-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

const a11yProps = (index: number) => {
  return {
    id: `scrollable-auto-tab-${index}`,
    'aria-controls': `scrollable-auto-tabpanel-${index}`,
  };
};

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    flexGrow: 1,
    width: '100%',
  },
}));

export const EstimationCalculator: FC = () => {
  const classes = useStyles();
  const [value, setValue] = useState(0);

  const handleChange = (event: ChangeEvent<{}>, newValue: number) => {
    setValue(newValue);
  };

  return (
    <div className={classes.root}>
      <div className="section-heading text-center">
        <h2 className="heading-line">{'Savings calculator'}</h2>
        <p className="lead text-muted">{'Savings calculator'}</p>
      </div>
      <Paper>
        <Tabs
          variant="fullWidth"
          value={value}
          indicatorColor="primary"
          textColor="primary"
          onChange={handleChange}
          aria-label="disabled tabs example"
        >
          <Tab label={<b>Usability Testing</b>} {...a11yProps(0)} />
          <Tab label={<b>Live Surveys</b>} {...a11yProps(1)} />
          <Tab label={<b>Marketing Campaigns</b>} {...a11yProps(2)} />
        </Tabs>
        <TabPanel value={value} index={0}>
          Calculator 1
        </TabPanel>
        <TabPanel value={value} index={1}>
          Calculator 2
        </TabPanel>
        <TabPanel value={value} index={2}>
          Calculator 3
        </TabPanel>
      </Paper>
    </div>
  );
};

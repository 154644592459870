import * as React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { makeStyles } from '@material-ui/core';

interface MoreInfoProps {
  to: string;
  className: string;
  text: string;
  width?: number;
  icon?: boolean;
  newTab?: boolean;
}

const MoreInfoButton: React.FC<MoreInfoProps> = ({
  to,
  className,
  text,
  width,
  icon = true,
  newTab = true,
}) => {
  const classes = useStyles();
  const newTabProps = newTab ? { target: '_blank', rel: 'noopener noreferrer' } : {};
  return (
    <a
      href={to}
      className={`${className}`}
      {...newTabProps}
      style={
        width
          ? {
              width: `${width}px`,
            }
          : {}
      }
    >
      <div>
        {text}
        {icon && (
          <div className={classes.icon}>
            <FontAwesomeIcon icon={['fas', 'long-arrow-alt-right']} className="icon" />
          </div>
        )}
      </div>
    </a>
  );
};

const useStyles = makeStyles(() => ({
  icon: {
    display: 'inline-block',
    float: 'right',
    marginLeft: '15px',
    marginRight: '0px',
  },
}));

export default MoreInfoButton;

import * as React from 'react';
import 'odometer/themes/odometer-theme-minimal.css';

import Gallery from '../comopnents/Gallery';
import Heading from '../comopnents/Heading';
import { FeatureBlocks } from '../comopnents/FeatureBlocks';
import Faqs from '../comopnents/Faqs';
import Try from '../comopnents/Try';
import AnalyticsMatters from '../comopnents/AnalyticsMatters';
import Integration from 'comopnents/Integration';
import { FeatureGalleryItem } from 'model/FeatureGalleryItem';
import { FC } from 'react';
import { Testimonials } from 'comopnents/Testimonials';

const galleryFeatures: FeatureGalleryItem[] = require('../data/featureGallery.json');
const faqs = require('../data/faq.json');
const featureOptions = require('../data/featureOptions.json');
const teamFeatures = require('../data/teamFeatures.json');

interface HomeProps {}

const Home: FC<HomeProps> = () => {
  return (
    <>
      <Heading />
      <Gallery features={galleryFeatures} />
      <AnalyticsMatters />
      <Try />
      <FeatureBlocks
        id="usecases"
        features={featureOptions.features}
        title={featureOptions.title}
        description={featureOptions.description}
        dark
      />
      <Testimonials />
      <FeatureBlocks
        id="features"
        features={teamFeatures.features}
        title={teamFeatures.title}
        description={teamFeatures.description}
        link={teamFeatures.link}
        dark
      />
      <Integration />
      <Faqs faqs={faqs} />
    </>
  );
};

export default Home;

import * as React from 'react';
import classNames from 'classnames';
import { NavbarBrand, NavItem, Nav, Container } from 'reactstrap';
import { NavLink, useLocation } from 'react-router-dom';

import { useScroll } from '../utils/media';
import { primary } from 'theme/colors';
import { makeStyles } from '@material-ui/core';
import { HashLink } from 'react-router-hash-link';
import { FC, useRef, useState } from 'react';

const urls = require('../data/urls.json');

const NAVBAR_STICKY_CLASS = 'navbar-sticky';

interface HeaderProps {}

const Header: FC<HeaderProps> = (props) => {
  const classes = useStyles(props);
  const location = useLocation();
  const isHomepage = location.pathname === '/';
  const scrollProgress = useScroll(window);
  const navbarRef = useRef<HTMLDivElement | null>(null);
  const [navbarExpanded, setNavbarExpanded] = useState(false);

  const toggleNavbar = () => {
    setNavbarExpanded(!navbarExpanded);
  };

  const closeNavbar = () => {
    setNavbarExpanded(false);
  };

  if (isHomepage) {
    if (scrollProgress >= 1) {
      if (navbarRef.current && !navbarRef.current.classList.contains(NAVBAR_STICKY_CLASS)) {
        navbarRef.current.classList.add(NAVBAR_STICKY_CLASS);
      }
    } else {
      if (navbarRef.current && navbarRef.current.classList.contains(NAVBAR_STICKY_CLASS)) {
        navbarRef.current.classList.remove(NAVBAR_STICKY_CLASS);
      }
    }
  }

  return (
    <nav
      ref={navbarRef}
      className={classNames(
        'navbar navbar-expand-md main-nav navigation fixed-top sidebar-left no-print',
        { 'navbar-expanded': navbarExpanded },
        { 'navbar-sticky': !isHomepage },
      )}
    >
      <Container>
        <button className="navbar-toggler" type="button" onClick={toggleNavbar}>
          <span className="icon-bar" />
          <span className="icon-bar" />
          <span className="icon-bar" />
        </button>

        <NavbarBrand className="mr-lg-5" to="/" tag={NavLink}>
          <img alt="M" src={'assets/icons/logo_p.svg'} className="logo logo-sticky" />
          <div className={classes.brandName}>MUPIXA</div>
        </NavbarBrand>

        <div className="collapse navbar-collapse">
          <div
            className="sidebar-brand"
            onClick={() => {
              closeNavbar();
            }}
          >
            <NavLink to="/">
              <img src={'assets/icons/logo_p.svg'} alt="M" className="logo" />
            </NavLink>
          </div>

          <Nav className="nav navbar-nav ml-auto" navbar={true}>
            <NavItem
              onClick={() => {
                closeNavbar();
              }}
            >
              <HashLink smooth={false} className="nav-link" to="/ux">
                Usability Testing
              </HashLink>
            </NavItem>
            <NavItem
              onClick={() => {
                closeNavbar();
              }}
            >
              <HashLink smooth={false} className="nav-link" to="/marketing">
                Marketing
              </HashLink>
            </NavItem>
            <NavItem
              onClick={() => {
                closeNavbar();
              }}
            >
              <HashLink smooth={false} className="nav-link" to="/#try">
                Demo
              </HashLink>
            </NavItem>
            <NavItem
              onClick={() => {
                closeNavbar();
              }}
            >
              <a
                className="nav-link"
                href={urls.tutorials}
                target="_blank"
                rel="noopener noreferrer"
              >
                Tutorials
              </a>
            </NavItem>
            <NavItem
              onClick={() => {
                closeNavbar();
              }}
            >
              <NavLink className="nav-link" to="/pricing">
                Pricing
              </NavLink>
            </NavItem>
            <NavItem>
              <a className="nav-link" href={urls.signin} target="_blank" rel="noopener noreferrer">
                Sign in
              </a>
            </NavItem>
          </Nav>
        </div>
      </Container>
    </nav>
  );
};

const useStyles = makeStyles(() => ({
  brandName: {
    display: 'inline-block',
    marginLeft: '10px',
    fontWeight: 'bold',
    letterSpacing: '5px',
    color: primary,
  },
}));

export default Header;

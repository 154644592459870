import * as React from 'react';
import Footer from './Footer';
import Header from './Header';
import ScrollIndicator from './ScrollIndicator';
import { FC } from 'react';

interface PageProps {}

const Page: FC<PageProps> = (props) => {
  return (
    <>
      <ScrollIndicator />
      <Header />
      <main>
        {props.children}
        <Footer />
      </main>
    </>
  );
};

export default Page;

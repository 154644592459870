import * as React from 'react';
import {
  BrowserRouter,
  Redirect,
  Route,
  RouteComponentProps,
  Switch,
  useHistory,
} from 'react-router-dom';
import { FC, lazy, useEffect } from 'react';
import Page from './comopnents/Page';

// Home shouldn't be lazy loaded, because it's the first page
import Home from './pages/Home';

const Pricing = lazy(() => import('./pages/Pricing'));
const Imprint = lazy(() => import('./pages/Imprint'));
const Terms = lazy(() => import('./pages/Terms'));
const Privacy = lazy(() => import('./pages/Privacy'));
const Security = lazy(() => import('./pages/Security'));
const Features = lazy(() => import('./pages/Features'));
const Ux = lazy(() => import('./pages/Ux'));
const Marketing = lazy(() => import('./pages/Marketing'));
const Blog = lazy(() => import('./pages/Blog'));

const ScrollToTop: FC = () => {
  const history = useHistory();
  useEffect(() => {
    const unlisten = history.listen((location) => {
      if (!location.hash) {
        // filter hash-based routing
        window.scrollTo(0, 0);
      }
    });
    return () => {
      unlisten();
    };
  }, [history]);

  return null;
};

interface RoutingProps {}

const Routing: FC<RoutingProps> = () => {
  const pageRender = (Content: FC<any>) => {
    return (renderProps: RouteComponentProps) => {
      return <Content {...renderProps} />;
    };
  };
  return (
    <BrowserRouter>
      <ScrollToTop />
      <Page>
        <Switch>
          <Route path="/" exact={true} render={pageRender(Home)} />
          <Route path="/pricing" exact={true} render={pageRender(Pricing)} />
          <Route path="/features" exact={true} render={pageRender(Features)} />
          <Route path="/ux" exact={true} render={pageRender(Ux)} />
          <Route path="/marketing" exact={true} render={pageRender(Marketing)} />
          <Route path="/blog" exact={true} render={pageRender(Blog)} />
          <Route path="/imprint" exact={true} render={pageRender(Imprint)} />
          <Route path="/privacy" exact={true} render={pageRender(Privacy)} />
          <Route path="/terms" exact={true} render={pageRender(Terms)} />
          <Route path="/security" exact={true} render={pageRender(Security)} />
          <Redirect path="/terms.html" exact={true} to="terms" />
          <Redirect to="/" />
        </Switch>
      </Page>
    </BrowserRouter>
  );
};

export default Routing;

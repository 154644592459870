import { makeStyles } from '@material-ui/core';
import classNames from 'classnames';
import * as React from 'react';
import { Fade } from 'react-reveal';
import { Col, Container, Row } from 'reactstrap';

import { useEffect, useState } from 'react';
import { primary, secondary } from 'theme/colors';
import { useIsMobile } from 'utils/media';

const AnalyticsMatters = (props: any) => {
  const classes = useStyles(props);
  const isMobile = useIsMobile();
  const [scrollPercentage, setScrollPercentage] = useState(0);

  useEffect(() => {
    document.addEventListener(
      'scroll',
      () => {
        const analytics = document.getElementById('scroll-analytics');
        if (analytics) {
          const currentScrollPercentage = (window.scrollY / document.body.scrollHeight) * 100;
          setScrollPercentage(-currentScrollPercentage);
        }
      },
      { passive: true },
    );
  }, []);

  return (
    <section className="section features-cant-miss dark-bg">
      <div className="shapes-container overflow-clear">
        <div className="shape shape-circle shape-circle-1">
          <Fade bottom={true} right={true}>
            <div />
          </Fade>
        </div>
        <div className="shape shape-circle shape-circle-2">
          <Fade bottom={true} left={true} delay={300}>
            <div />
          </Fade>
        </div>
        <div className="shape shape-circle shape-circle-3">
          <Fade bottom={true} right={true} delay={400}>
            <div />
          </Fade>
        </div>
        <div className="shape shape-circle shape-circle-4">
          <Fade bottom={true} right={true} delay={600}>
            <div />
          </Fade>
        </div>

        <div className="shape shape-triangle shape-animated">
          <div className="animation--rotating" />
        </div>
      </div>
      <Container>
        <Row className="gap-y">
          <Col md={{ size: 6, order: 'last' }}>
            <div className={classNames('section-heading', isMobile ? 'text-center' : '')}>
              <h2 className="heading-line">ANALYTICS ARE ESSENTIAL</h2>
            </div>

            <ul className="list-unstyled">
              <li className="media flex-column flex-md-row text-center text-md-left">
                <i className="mx-auto mr-md-3 mx-auto mr-md-3 accent pe pe-7s-mail pe-3x" />
                <div className="media-body mt-3 mt-md-0">
                  <h5 className="bold mt-0 mb-1">Leverage your designs</h5>
                  <p className="m-0 justify">
                    Use your existing designs for <b>usability testing</b>,{' '}
                    <b>market-research surveys</b> or <b>live marketing campaigns</b>.
                  </p>
                </div>
              </li>

              <li className="media flex-column flex-md-row text-center text-md-left mt-4">
                <i className="mx-auto mr-md-3 mx-auto mr-md-3 accent pe pe-7s-users pe-3x" />
                <div className="media-body mt-3 mt-md-0">
                  <h5 className="bold mt-0 mb-1">Batteries included</h5>
                  <p className="m-0 justify">
                    Mupixa automatically collects analytics along the way. No coding or complicated
                    setup required.
                  </p>
                </div>
              </li>

              <li className="media flex-column flex-md-row text-center text-md-left mt-4">
                <i className="mx-auto mr-md-3 mx-auto mr-md-3 accent pe pe-7s-graph1 pe-3x" />
                <div className="media-body mt-3 mt-md-0">
                  <h5 className="bold mt-0 mb-1">Research, Validate and Iterate</h5>
                  <p className="m-0 justify">
                    Conduct market research, validate your designs, and iterate based on data-driven
                    decisions.
                  </p>
                </div>
              </li>
            </ul>
          </Col>

          <Col md="6">
            <div className="iphone-x front light mx-auto">
              <div className={classNames('screen shadow-box', classes.screen)}>
                <div
                  id="scroll-analytics"
                  style={{
                    height: '100%',
                    backgroundAttachment: 'scroll',
                    backgroundPosition: `center ${scrollPercentage * 10 - 100}px`,
                    backgroundRepeat: 'repeat-y',
                    backgroundSize: 'cover',
                    backgroundImage: `url(assets/imgs/analytics-long.png)`,
                  }}
                ></div>
              </div>
              <div className="notch" />
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

const useStyles = makeStyles(() => ({
  screen: {
    height: '450px',
    backgroundImage: `linear-gradient(45deg, ${primary}, ${secondary})`,
    backgroundSize: '200% 200%',
    animation: 'gradient 5s ease infinite',
  },
}));

export default AnalyticsMatters;

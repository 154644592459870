import React from 'react';
import classNames from 'classnames';
import { makeStyles } from '@material-ui/core';
import { makeRelative } from 'utils/assets';

const RELATIVE_PATH = '/assets/imgs/gallery';
interface FeatureImageProps {
  name: string;
}

const FeatureImage: React.FC<FeatureImageProps> = (props) => {
  const classes = useStyles(props);
  return (
    <div className={classNames('browser', classes.shadow, classes.browser)}>
      <div className={classNames('screen shadow-box')}>
        <picture>
          <source srcSet={makeRelative(props.name, RELATIVE_PATH, 'webp')} type="image/webp" />
          <source srcSet={makeRelative(props.name, RELATIVE_PATH, 'png')} type="image/png" />
          <img src={makeRelative(props.name, RELATIVE_PATH, 'png')} alt="feature-img" />
        </picture>
      </div>
    </div>
  );
};

const useStyles = makeStyles(() => ({
  browser: {
    margin: 'auto',
    height: 'fit-content',
  },
  shadow: {
    boxShadow: '0 1px 0 #aeb5bb, 0 10px 20px -5px rgba(0,0,0,0.5);',
  },
}));

export default FeatureImage;

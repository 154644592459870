export const primary = '#6A52FC';
export const secondary = 'rgba(0,251,161,0.80)';
export const aux = '#e73c7e';
export const primaryMute = '#1B0995';
export const text = 'rgba(0, 0, 0, 0.87)';
export const white = 'rgb(250, 250, 250)';
export const background = 'rgb(250, 250, 250)';
export const textLight = '#b5bab6'; // '#8795A1';

export const green = '#5cffc3';
export const grey = '#edf4f8';

import * as React from 'react';
import { Container, Row, Col, UncontrolledCollapse } from 'reactstrap';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Fade } from 'react-reveal';
import { Faq } from '../model/Faq';
import MoreInfoButton from './common/MoreInfoButton';

interface FaqsProps {
  faqs: Faq[];
}

const Faqs: React.FC<FaqsProps> = (props) => {
  return (
    <section className="section faqs-dd dark-bg">
      <div className="shapes-container">
        <div className="absolute icon">
          <Fade top={true} left={true}>
            <FontAwesomeIcon icon={['fas', 'question']} />
          </Fade>
        </div>
      </div>

      <Container>
        <div className="section-heading text-center">
          <h2 className="heading-line">FREQUENT QUESTIONS</h2>
          <p className="lead">Want to know more?</p>
        </div>

        <Row style={{ zIndex: 10, position: 'relative' }}>
          <Col lg="8" className="mx-lg-auto bring-to-front">
            {props.faqs.map((faq, i) => (
              <Fade bottom={true} key={i}>
                <div className="card shadow-box shadow-hover mb-3" key={i}>
                  <Link to="#" className="card-title collapsed" id={`toggler-${i}`}>
                    <div className="card-header py-3">{faq.question}</div>
                  </Link>

                  <UncontrolledCollapse toggler={`#toggler-${i}`}>
                    <div className="card-body">
                      {faq.answer}
                      {faq.link && (
                        <div>
                          <MoreInfoButton
                            to={faq.link}
                            text={'Help Center'}
                            className="btn btn-primary btn-md text-contrast ml-md-auto"
                            icon={false}
                            width={150}
                          />
                        </div>
                      )}
                    </div>
                  </UncontrolledCollapse>
                </div>
              </Fade>
            ))}
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default Faqs;

import * as React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Container, Row, Col } from 'reactstrap';
import { FeatureElement } from '../model/FeatureElement';
import { FC } from 'react';
import MoreInfoButton from './common/MoreInfoButton';
import { EstimationCalculator } from './EstimationCalculator';

const renderFeatures = (features: FeatureElement[]) => {
  return features.map((element, i) => {
    return (
      <Col
        key={i}
        md={12 / features.length}
        className="py-4 shadow-hover"
        style={{ textAlign: 'center' }}
      >
        {!element.imageUrl && (
          <FontAwesomeIcon icon={element.icon} className="display-4 display-md-4 text-primary" />
        )}
        {element.imageUrl && <img src={element.imageUrl} alt="feature" />}
        <h5 className="bold" style={{ margin: '1rem' }}>
          {element.title}
        </h5>
        <p className={'justify'}>{element.description}</p>
      </Col>
    );
  });
};

interface FeaturesProps {
  id?: string;
  title: string;
  description: string;
  features: FeatureElement[];
  link?: string;
  dark?: boolean;
  calculator?: boolean;
}

export const FeatureBlocks: FC<FeaturesProps> = (props: FeaturesProps) => {
  return (
    <section id={props.id} className={`section features ${props.dark ? 'dark-bg' : ''}`}>
      <Container>
        <div className="section-heading text-center">
          <h2 className="heading-line">{props.title}</h2>
          <p className="lead text-muted">{props.description}</p>
        </div>

        <Row className="gap-y text-center text-md-left">{renderFeatures(props.features)}</Row>
        {props.link && (
          <div style={{ textAlign: 'center', width: '100%', margin: 'auto', marginTop: '50px' }}>
            {
              <MoreInfoButton
                className="btn btn-primary btn-lg text-contrast ml-md-auto"
                text="See more"
                to={props.link}
                newTab={false}
              />
            }
          </div>
        )}
        {props.calculator && <EstimationCalculator />}
      </Container>
    </section>
  );
};

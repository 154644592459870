import { makeStyles } from '@material-ui/core';
import classNames from 'classnames';
import * as React from 'react';
import { Container, Row, Col } from 'reactstrap';
import { useIsMobile } from 'utils/media';
import MoreInfoButton from './common/MoreInfoButton';

import { textLight } from 'theme/colors';

const urls = require('../data/urls.json');

const items = [
  { icon: 'figma', name: 'Figma', link: urls.plugins.figma },
  // { icon: 'canva', name: 'Canva', link: urls.plugins.canva },
  { icon: 'xd', name: 'Adobe XD', link: urls.plugins.xd },
  { icon: 'sketch', name: 'Sketch', link: urls.plugins.sketch },
  { icon: 'mupixa', name: 'Use Images', link: urls.signin },
];

const Integration = (props: any) => {
  const isMobile = useIsMobile();
  const classes = useStyles(props);
  return (
    <section id="plugins" className="section integration-bubbles">
      <Container className="overflow-hidden">
        <Row>
          <Col md="6">
            <div className={classes.container}>
              <table style={{ width: '100%' }}>
                <tbody>
                  {items.map((item, i) => {
                    return (
                      <tr key={i}>
                        <td style={{ textAlign: 'center' }}>
                          <img
                            src={`assets/imgs/icons/${item.icon}.svg`}
                            alt="icon"
                            className={classes.icon}
                          />
                        </td>
                        <td style={{ textAlign: 'left' }}>
                          <MoreInfoButton
                            to={item.link}
                            text={item.name}
                            className="btn btn-primary btn-md text-contrast ml-md-auto"
                            width={150}
                          />
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </Col>

          <Col md="6">
            <div className={classNames('section-heading', isMobile ? 'text-center' : '')}>
              <h2 className="heading-line">LEVERAGE YOUR EXISTING DESIGNS</h2>
              <p className="lead text-muted justify">
                Import your designs using our plugins. Designs and interactions are automatically
                imported.
              </p>
              <p className="lead text-muted justify">
                Alternatively upload images and make them interactive using Mupixa.
              </p>
            </div>
          </Col>
        </Row>
        <div className={classes.disclaimer}>
          <p>Adobe XD and Adobe XD Logo are trademarks of Adobe Inc.</p>
          <p>Figma and Figma Logo are trademarks of Figma Inc.</p>
          <p>Sketch and Sketch Logo are trademarks of Sketch B.V.</p>
          {/* <p>Canva and Canva Logo are trademarks of Canva Inc.</p> */}
        </div>
      </Container>
    </section>
  );
};

const useStyles = makeStyles(() => ({
  container: {},
  icon: {
    height: '75px',
    margin: '10px',
  },
  disclaimer: {
    position: 'absolute',
    bottom: '15px',
    right: '15px',
    color: textLight,
    lineHeight: '3px',
    fontSize: '9px',
    textAlign: 'right',
  },
}));

export default Integration;

import * as React from 'react';
import { Container } from 'reactstrap';
import { FeatureGalleryItem } from '../model/FeatureGalleryItem';
import { useIsMobile } from '../utils/media';
import DesktopView from './gallery/DesktopView';
import MobileView from './gallery/MobileView';

interface GalleryProps {
  features: FeatureGalleryItem[];
}

const Gallery: React.FC<GalleryProps> = (props) => {
  const isMobile = useIsMobile();

  return (
    <section id="gallery" className="section">
      <Container>
        <div className="section-heading text-center">
          <h2 className="heading-line">STOP GUESSING. START EXPLORING!</h2>
          <p>
            Discover the <b>WHY</b>, <b>WHAT</b> and <b>HOW</b> of your users.
          </p>
        </div>
        {isMobile ? (
          <MobileView features={props.features} />
        ) : (
          <DesktopView features={props.features} />
        )}
      </Container>
    </section>
  );
};

export default Gallery;

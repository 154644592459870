import { makeStyles } from '@material-ui/core';
import classNames from 'classnames';
import * as React from 'react';
import { Col, Container, Row } from 'reactstrap';
import { Fade } from 'react-reveal';
import { primary, secondary, white } from 'theme/colors';
import { useIsMobile } from 'utils/media';
import EmailCollector from './EmailCollector';
import { Call2Action } from './Call2Action';

const urls = require('../data/urls.json');

interface TryProps {}

const Try: React.FC<TryProps> = (props) => {
  const classes = useStyles(props);
  const isMobile = useIsMobile();
  const [dialogOpen, setDialogOpen] = React.useState(false);
  const enableFunnel = true;

  return (
    <section id="try" className="section">
      <div className="shapes-container">
        {/* animated shapes */}
        <div className="animation-shape shape-ring animation--clockwise" />
        <div className="animation-shape shape-circle shape-circle-1 animation--anti-clockwise" />
        <div className="animation-shape shape-circle shape-circle-2 animation--clockwise" />
        <div className="animation-shape shape-heart animation--clockwise">
          <div className="animation--rotating" />
        </div>
      </div>
      <Container>
        <div className="section-heading text-center">
          <h2 className="heading-line">TRY MUPIXA</h2>
          <p className="lead text-muted">
            No applications or complicated setups required. Mupixa is web-native and runs on every
            modern browser.
          </p>
        </div>
        <Row>
          <Col md="6">
            <Fade left={true} delay={100}>
              <div className={classNames({ [classes.margin]: isMobile })}>
                <div
                  className={classNames(
                    classes.growOnHover,
                    classes.relative,
                    'iphone-x front light mx-auto',
                  )}
                >
                  <a href={urls.demoPrototype} target="_blank" rel="noopener noreferrer">
                    <div className={classNames('screen shadow-box', classes.screenPhone)} />
                    <div className={classNames(classes.center, classes.text)}>
                      <span>TRY OUR DESIGNS PLAYER</span>
                    </div>
                  </a>
                  <div className="notch" />
                </div>
              </div>
            </Fade>
          </Col>
          <Col md="6">
            <Fade right={true} delay={100}>
              <div className={classNames({ [classes.margin]: isMobile })}>
                <div className={classNames(classes.shadow, classes.growOnHover, 'browser mx-auto')}>
                  <div
                    className={classNames('screen shadow-box', classes.relative)}
                    onClick={() => {
                      if (enableFunnel) {
                        setDialogOpen(true);
                      }
                    }}
                  >
                    {enableFunnel ? (
                      <>
                        <div className={classNames(classes.screenBrowser, classes.pointer)} />
                        <div className={classNames(classes.center, classes.text)}>
                          <span>EXPLORE DEMO ANALYTICS</span>
                        </div>
                      </>
                    ) : (
                      <a href={urls.demoAnalytics} target="_blank" rel="noopener noreferrer">
                        <div className={classes.screenBrowser} />
                        <div className={classNames(classes.center, classes.text)}>
                          <span>EXPLORE DEMO ANALYTICS</span>
                        </div>
                      </a>
                    )}
                  </div>
                </div>
              </div>
            </Fade>
            <EmailCollector
              open={dialogOpen}
              close={() => {
                setDialogOpen(false);
              }}
            />
          </Col>
        </Row>
      </Container>
      <Call2Action />
    </section>
  );
};

const useStyles = makeStyles(() => ({
  margin: {
    marginTop: '15px',
    marginBottom: '15px',
  },
  relative: { position: 'relative' },
  shadow: {
    boxShadow: '0 1px 0 #aeb5bb, 0 10px 20px -5px rgba(0,0,0,0.5);',
  },
  growOnHover: {
    transition: '.3s ease-in-out',
    '&:hover': {
      transform: 'scale(1.05)',
    },
  },
  screenPhone: {
    height: '450px',
    backgroundImage: `linear-gradient(45deg, ${primary}, ${secondary})`,
    backgroundSize: '200% 200%',
    animation: 'gradient 5s ease infinite',
  },
  screenBrowser: {
    height: '439px',
    backgroundImage: `linear-gradient(-45deg, ${primary}, ${secondary})`,
    backgroundSize: '200% 200%',
    animation: 'gradient 5s ease infinite',
  },
  center: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
  },
  text: {
    color: white,
    fontWeight: 'bold',
    textAlign: 'center',
  },
  pointer: {
    cursor: 'default',
    '&:hover': {
      cursor: 'pointer',
    },
  },
}));

export default Try;

import * as React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Nav, Container, Row, Col } from 'reactstrap';
import classNames from 'classnames';
import { NavHashLink } from 'react-router-hash-link';

import { NavLink } from 'react-router-dom';
import { primary, white } from 'theme/colors';
import { makeStyles } from '@material-ui/core';

const urls = require('../data/urls.json');

interface FooterProps {}

const Footer: React.FC<FooterProps> = (props) => {
  const classes = useStyles(props);
  return (
    <footer className="site-footer section footer-dark text-contrast no-print">
      <div className="footer-edge" />
      <Container className="py-3">
        <Row className="row gap-y text-center text-md-left">
          <Col md="4" className="mr-auto">
            <a href="/">
              <img src={'assets/icons/logo_w.svg'} alt="" className="logo" />
            </a>
            <p>An all-in-one analytics platform covering every touchpoint.</p>
          </Col>

          <Col md="2">
            <Nav className="flex-column" tag="nav">
              <NavLink className={classNames('py-2', classes.link)} to="/">
                Homepage
              </NavLink>
              <NavLink className={classNames('py-2', classes.link)} to="/pricing">
                Pricing
              </NavLink>
              <NavLink className={classNames('py-2', classes.link)} to="/ux">
                Usability Testing
              </NavLink>
              <NavLink className={classNames('py-2', classes.link)} to="/marketing">
                Marketing
              </NavLink>
            </Nav>
          </Col>

          <Col md="2">
            <Nav className="flex-column" tag="nav">
              <a
                className={classNames('py-2', classes.link)}
                href={urls.demoPrototype}
                target="_blank"
                rel="noopener noreferrer"
              >
                Demo Prototype
              </a>
              <a
                className={classNames('py-2', classes.link)}
                href={urls.demoAnalytics}
                target="_blank"
                rel="noopener noreferrer"
              >
                Demo Analytics
              </a>
              <a
                className={classNames('py-2', classes.link)}
                href={urls.social.discord}
                target="_blank"
                rel="noopener noreferrer"
              >
                Discord Chat
              </a>
              <a
                className={classNames('py-2', classes.link)}
                href={urls.help}
                target="_blank"
                rel="noopener noreferrer"
              >
                Help Center
              </a>
              <a
                className={classNames('py-2', classes.link)}
                href={urls.tutorials}
                target="_blank"
                rel="noopener noreferrer"
              >
                Tutorials
              </a>
            </Nav>
          </Col>

          <Col md="2">
            <Nav className="flex-column" tag="nav">
              <NavLink className={classNames('py-2', classes.link)} to="/imprint">
                Imprint
              </NavLink>
              <NavLink className={classNames('py-2', classes.link)} to="/terms">
                Terms of Service
              </NavLink>
              <NavLink className={classNames('py-2', classes.link)} to="/privacy">
                Privacy Policy
              </NavLink>
              <NavLink to="/security" className={classNames('py-2', classes.link)}>
                <span>Security</span>
              </NavLink>
              <NavHashLink
                smooth={false}
                className={classNames('py-2', classes.link)}
                to="/privacy#cookies"
              >
                <span>Cookies</span>
              </NavHashLink>
            </Nav>
          </Col>

          <Col md="2">
            <h6 className="py-2 small">Follow us</h6>

            <nav className="nav justify-content-around">
              <a
                href={urls.social.linkedin}
                target="_blank"
                rel="noopener noreferrer"
                className={classNames('btn btn-circle btn-m', classes.link)}
              >
                <FontAwesomeIcon icon={['fab', 'linkedin']} />
              </a>
              <a
                href={urls.social.twitter}
                target="_blank"
                rel="noopener noreferrer"
                className={classNames('btn btn-circle btn-m', classes.link)}
              >
                <FontAwesomeIcon icon={['fab', 'x-twitter']} />
              </a>
              <a
                href={urls.social.discord}
                target="_blank"
                rel="noopener noreferrer"
                className={classNames('btn btn-circle btn-m', classes.link)}
              >
                <FontAwesomeIcon icon={['fab', 'discord']} />
              </a>
            </nav>
          </Col>
        </Row>

        <hr className="mt-5 op-5" />

        <Row className="small">
          <Col md="4">
            <p className="mt-2 mb-0 text-center text-md-left">
              <FontAwesomeIcon icon={['far', 'copyright']} /> {new Date().getFullYear()}{' '}
              <a
                href={urls.mupixa}
                style={{ color: primary }}
                target="_blank"
                rel="noopener noreferrer"
              >
                {'Mupixa'}
              </a>
              {'. All Rights Reserved.'}
            </p>
          </Col>
          <Col md="2" />
          <Col md="6">
            <p className="mt-2 mb-0 text-center text-md-right">
              Made with <FontAwesomeIcon icon={['fas', 'heart']} /> &amp;{' '}
              <FontAwesomeIcon icon={['fas', 'coffee']} /> in Munich, Germany
            </p>
          </Col>
        </Row>
      </Container>
    </footer>
  );
};

const useStyles = makeStyles(() => ({
  link: {
    color: white,
    '&:hover': {
      color: primary,
    },
  },
}));

export default Footer;

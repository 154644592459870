import * as React from 'react';
import { FC } from 'react';
import { Col, Container, Row } from 'reactstrap';
import MoreInfoButton from './common/MoreInfoButton';
const urls = require('../data/urls.json');

export const Call2Action: FC = () => (
  <Container>
    <Row className="align-items-center">
      <Col md="8">
        <div className="text-center text-md-left">
          <h4 className="bold accent">Get feedback in minutes instead of days or weeks!</h4>
          <p className="mt-0">Ready to make the most of every interaction with your designs?</p>
        </div>
      </Col>
      <Col md="4" className="text-center text-md-right">
        <MoreInfoButton
          className="btn btn-primary btn-lg text-contrast p-4 ml-md-auto"
          text="Get Started"
          to={urls.signup}
          icon={false}
        />
      </Col>
    </Row>
  </Container>
);

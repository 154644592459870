import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { makeStyles } from '@material-ui/core';
import classNames from 'classnames';
import * as React from 'react';
import { FC, useEffect, useRef, useState } from 'react';
import { NavLink } from 'react-router-dom';
import { primary } from 'theme/colors';
import { FeatureGalleryItem } from '../../model/FeatureGalleryItem';
import { idxAllowed } from '../../utils/array';
import { SCROLL_DIRECTION, useScroll } from '../../utils/media';
import FeatureImage from './FeatureImage';

interface GalleryProps {
  features: FeatureGalleryItem[];
}

const MobileView: FC<GalleryProps> = (props) => {
  const classes = useStyles(props);
  const [featureIdx, setFeatureIdx] = useState(0);
  const [refListCurrentNode, setRefListCurrentNode] = useState<any>(undefined);

  const listRef = useRef<HTMLDivElement>(null);
  useEffect(() => {
    if (listRef.current !== refListCurrentNode) {
      setRefListCurrentNode(listRef.current);
    }
  }, [listRef, refListCurrentNode]);
  const scrollProgress = useScroll(refListCurrentNode, SCROLL_DIRECTION.X);
  if (listRef.current && scrollProgress) {
    const scrollWidth = listRef.current.scrollWidth - listRef.current.clientWidth;
    const scrollPerItem = scrollWidth / props.features.length;
    const idx = Math.floor(scrollProgress / scrollPerItem);
    if (idx !== featureIdx && idxAllowed(idx, props.features)) {
      setFeatureIdx(idx);
    }
  }

  return (
    <div className={classes.containerMobile}>
      <div ref={listRef} className={classes.sidepanelMobile}>
        {props.features.map((feature, idx) => {
          const selected = idx === featureIdx;
          return (
            <div
              key={idx}
              className={classNames(classes.itemMobile, {
                [classes.itemSelected]: selected,
              })}
            >
              <div
                className={classNames(classes.title, {
                  [classes.titleSelected]: selected,
                })}
              >
                {feature.title}
              </div>
              <div className={classes.text}>{feature.description}</div>
              {selected && <div className={classNames(classes.loader, classes.full)} />}
              {0 === idx && (
                <div className={classes.scrollHint}>
                  <span>{'swipe for more >>'}</span>
                </div>
              )}
            </div>
          );
        })}
        <div className={classes.itemMobile}>
          <div className={classes.title}>
            <NavLink to="/features">
              {'More'}
              <FontAwesomeIcon icon={['fas', 'long-arrow-alt-right']} className="ml-2" />
            </NavLink>
          </div>
        </div>
      </div>
      <div className={classes.pictureMobile}>
        <FeatureImage name={props.features[featureIdx].imgName} />
      </div>
    </div>
  );
};

const useStyles = makeStyles(() => ({
  containerMobile: {
    whiteSpace: 'nowrap',
    position: 'relative',
  },
  sidepanelMobile: {
    position: 'relative',
    width: '100%',
    overflowX: 'scroll',
    paddingBottom: '5px',
    marginBottom: '5px',
  },
  pictureMobile: {
    position: 'relative',
    width: '100%',
    textAlign: 'center',
  },
  itemMobile: {
    position: 'relative',
    display: 'inline-block',
    width: '270px',
    height: '215px',
    whiteSpace: 'normal',
    margin: '10px',
    verticalAlign: 'top',
    marginLeft: '10px',
    marginRight: '10px',
    overflow: 'auto', // so that child margins are respected
  },
  itemSelected: {
    backgroundColor: '#fff',
    boxShadow: '0 2px 10px rgba(69,74,253,.13)',
    borderRadius: '5px',
  },
  scrollHint: {
    textAlign: 'right',
    fontSize: '11px',
    position: 'absolute',
    bottom: '5px',
    right: '5px',
  },
  title: {
    userSelect: 'none',
    msUserSelect: 'none',
    mozUserSelect: 'none',
    webkitUserSelect: 'none',
    margin: '25px',
    marginBottom: '5px',
    fontWeight: 'bold',
    fontSize: '1.2em',
    '&:hover': {
      color: primary,
    },
  },
  text: {
    userSelect: 'none',
    msUserSelect: 'none',
    mozUserSelect: 'none',
    webkitUserSelect: 'none',
    margin: '25px',
    marginTop: '5px',
    textAlign: 'justify',
    fontSize: '80%',
  },
  titleSelected: {
    color: primary,
  },
  loader: {
    backgroundColor: primary,
    height: '3px',
    position: 'absolute',
    bottom: '0',
    left: '0',
  },
  full: {
    width: '100%',
  },
}));

export default MobileView;

import React, { FC } from 'react';
import { A11y, Autoplay, Navigation, Pagination } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react/swiper-react.js';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Container, Row } from 'reactstrap';

// Styles must use direct files imports
import 'swiper/swiper.scss'; // core Swiper
import 'swiper/modules/autoplay/autoplay.scss'; // Pagination module
import 'swiper/modules/a11y/a11y.scss'; // Pagination module
import 'swiper/modules/navigation/navigation.scss'; // Navigation module
import 'swiper/modules/pagination/pagination.scss'; // Pagination module
import { primary } from 'theme/colors';

const testimonials = require('../data/testimonials.json');

export const Testimonials: FC<{ dark?: boolean }> = ({ dark }) => {
  return (
    <section id="testimonials" className={`section features ${dark ? 'dark-bg' : ''}`}>
      <Container>
        <Row className="gap-y text-center text-md-left">
          <Swiper
            style={
              { '--swiper-navigation-color': primary, '--swiper-pagination-color': primary } as any
            }
            modules={[A11y, Autoplay, Navigation, Pagination]}
            slidesPerView={1}
            threshold={2}
            spaceBetween={10}
            navigation={true}
            pagination={{ clickable: true }}
            loop={true}
            autoplay={{ delay: 7000 }}
          >
            {testimonials.map((testimonial: Testimonial) => (
              <SwiperSlide key={testimonial.name}>
                <TestimonialTile testimonial={testimonial} />
              </SwiperSlide>
            ))}
          </Swiper>
        </Row>
      </Container>
    </section>
  );
};

type Testimonial = {
  position: string;
  name: string;
  picture: string;
  text: string;
};

const TestimonialTile: FC<{ testimonial: Testimonial }> = ({ testimonial }) => {
  return (
    <div
      style={{
        textAlign: 'center',
        marginTop: '2rem',
        marginBottom: '5rem',
        marginLeft: '7rem',
        marginRight: '7rem',
      }}
    >
      <div>
        <img
          src={testimonial.picture}
          alt={testimonial.name}
          style={{
            width: '115px',
            height: '115px',
            borderRadius: '50%',
            marginBottom: '1rem',
            boxShadow:
              '-1px 3px 1px -2px grey, 0px 2px 2px 0px rgba(20,20,20,0.9), 0px 1px 5px 0px rgba(0,0,0,0.12)',
          }}
        />
        <div>
          <b>{testimonial.position}</b>
          <br />
          <small>{testimonial.name}</small>
        </div>
      </div>
      <div>
        <FontAwesomeIcon
          icon={['fas', 'quote-left']}
          className="text-primary"
          size="1x"
          style={{ marginRight: '0.5rem' }}
        />
        {testimonial.text}
      </div>
    </div>
  );
};

import * as React from 'react';
import { makeStyles } from '@material-ui/core';
import classNames from 'classnames';

import { FeatureGalleryItem } from '../../model/FeatureGalleryItem';
import { primary } from 'theme/colors';
import { NavLink } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import FeatureImage from './FeatureImage';
import { FC, useEffect, useState } from 'react';

const SECONDS_PER_FEATURE = 15;

interface GalleryProps {
  features: FeatureGalleryItem[];
}

const DesktopView: FC<GalleryProps> = (props) => {
  const classes = useStyles(props);
  const [featureIdx, setFeatureIdx] = useState(0);
  const [featureTimeout, setFeatureTimeout] = useState<NodeJS.Timeout | undefined>(undefined);
  const [disabledAutorotation, setDisabledAutorotation] = useState(false);
  const stopAutorotation = () => {
    if (!!featureTimeout) {
      clearTimeout(featureTimeout);
      setFeatureTimeout(undefined);
      setDisabledAutorotation(true);
    }
  };

  const getNextFeatureIdx = (currentIdx: number, features: FeatureGalleryItem[]) => {
    const idx = currentIdx + 1;
    if (idx >= 0 && idx < features.length) {
      return idx;
    }
    return 0;
  };

  useEffect(() => {
    if (disabledAutorotation) {
      return;
    }
    const timeout = setTimeout(() => {
      setFeatureIdx(getNextFeatureIdx(featureIdx, props.features));
    }, SECONDS_PER_FEATURE * 1000);
    setFeatureTimeout(timeout);
    return () => {
      return clearTimeout(timeout);
    };
  }, [featureIdx, disabledAutorotation, props.features]);

  return (
    <div className={classes.containerDesktop}>
      <div className={classNames(classes.columnImage, classes.paddingRight)}>
        <FeatureImage name={props.features[featureIdx].imgName} />
      </div>
      <div className={classNames(classes.columnDescription, classes.paddingLeft)}>
        {props.features.map((feature, idx) => {
          const selected = idx === featureIdx;
          return (
            <div
              key={idx}
              className={classNames(classes.itemDesktop, {
                [classes.itemSelected]: selected,
              })}
              onClick={() => {
                setFeatureIdx(idx);
                stopAutorotation();
              }}
            >
              <div
                className={classNames(classes.title, {
                  [classes.titleSelected]: selected,
                })}
              >
                {feature.title}
              </div>
              {selected && (
                <React.Fragment>
                  <div className={classes.text}>{feature.description}</div>
                  <div
                    className={classNames(classes.loader, {
                      [classes.full]: !featureTimeout,
                    })}
                    style={
                      !!featureTimeout
                        ? {
                            animation: `progressBar ${SECONDS_PER_FEATURE}s`,
                            animationFillMode: 'both',
                          }
                        : {}
                    }
                  />
                </React.Fragment>
              )}
            </div>
          );
        })}
        <div className={classes.itemDesktop}>
          <div className={classes.title}>
            <NavLink to="/features">
              {'More'}
              <FontAwesomeIcon icon={['fas', 'long-arrow-alt-right']} className="ml-2" />
            </NavLink>
          </div>
        </div>
      </div>
    </div>
  );
};

const useStyles = makeStyles(() => ({
  containerDesktop: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  columnImage: {
    flex: '0 0 65%',
    maxWidth: '65%',
    position: 'relative',
    width: '100%',
    display: 'flex',
  },
  columnDescription: {
    flex: '0 0 35%',
    maxWidth: '35%',
    position: 'relative',
    width: '100%',
  },
  paddingRight: {
    paddingRight: '10px',
  },
  paddingLeft: {
    paddingLeft: '10px',
  },
  itemSelected: {
    backgroundColor: '#fff',
    boxShadow: '0 2px 10px rgba(69,74,253,.13)',
    borderRadius: '5px',
  },
  itemDesktop: {
    cursor: 'pointer',
    overflow: 'auto', // so that child margins are respected
    whiteSpace: 'normal',
    position: 'relative',
  },
  scrollHint: {
    textAlign: 'right',
    fontSize: '11px',
    position: 'absolute',
    bottom: '5px',
    right: '5px',
  },
  title: {
    userSelect: 'none',
    msUserSelect: 'none',
    mozUserSelect: 'none',
    webkitUserSelect: 'none',
    margin: '15px',
    fontWeight: 'bold',
    fontSize: '1.2em',
    '&:hover': {
      color: primary,
    },
  },
  text: {
    userSelect: 'none',
    msUserSelect: 'none',
    mozUserSelect: 'none',
    webkitUserSelect: 'none',
    margin: '25px',
    marginTop: '5px',
    textAlign: 'justify',
    fontSize: '80%',
  },
  loader: {
    backgroundColor: primary,
    height: '3px',
    position: 'absolute',
    bottom: '0',
    left: '0',
  },
  full: {
    width: '100%',
  },
  titleSelected: {
    color: primary,
  },
}));

export default DesktopView;
